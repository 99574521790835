<template>
  <Dialog v-if='visible' v-model='visible' :options='dialogOptions.dialog'>
    <v-row>
      <v-col :cols='12' class='img-box'>
        <img ref='img' :alt='imgUrl' :src='imgUrl' style="width: 100%;object-fit: contain" @click='imgFull'/>
      </v-col>
      <!--      <v-col :cols='12' class='img-box'>-->
      <!--        <k-warning-btn icon='mdi-radiobox-blank' text='全屏' @click='imgFull'></k-warning-btn>-->
      <!--      </v-col>-->
    </v-row>
<!--    <div v-if='fullState' class='mask' @click.stop='fullState = false'>-->
<!--      <div ref='w'>-->
<!--        <img :alt='imgUrl' :src='imgUrl' :style='{width:`${iW}px`,height:`${iH}px`}'-->
<!--             @wheel='onwheel'/>-->
<!--      </div>-->
<!--    </div>-->
  </Dialog>
</template>

<script>
import {assiginDeep} from '@/views/screen/config';
import Dialog from '@/views/screen/components/Dialog.vue';

export default {
  //组件注册
  components: {
    Dialog,
  },
  //组件传值
  props: {},
  data() {
    return {
      imgUrl: '',
      scaleNum: 1,
      iW: 0,
      iH: 0,

      visible: false,
      fullState: false,
      dialogOptions: {
        dialog: {
          width: 1300,
          title: '预览',
          fullscreen:true
        },
      },
    };
  },
  //计算属性
  computed: {},
  //属性监听
  watch: {
    visible() {
      if (!this.visible) {
        this.imgUrl = '';
      }
    },
  },
  //DOM访问
  mounted() {
  },
  //保存方法
  methods: {
    openDialog(options = {}) {
      this.dialogOptions = assiginDeep(this.dialogOptions, {...options});
      this.imgUrl = options.imgUrl;
      this.visible = true;
    },
    imgFull() {
      this.fullState = true;
      this.$nextTick(() => {
        this.iW = this.$refs.w.clientWidth;
        this.iH = this.$refs.w.clientHeight;
      });
    },
    onwheel(e) {
      let evt = e || window.event;
      e.preventDefault();
      // console.log(evt.deltaY);
      if (evt.deltaY > 0) {
        this.iW = this.iW / 1.01;
        this.iH = this.iH / 1.01;
      } else {
        this.iW = this.iW * 1.01;
        this.iH = this.iH * 1.01;
      }
    },

  },
};
</script>

<style lang='scss' scoped>
.img-box {
  display: flex;
  justify-content: center;
  align-items: center img {
    max-width: 660px;
    max-height: 376px;
    object-fit: contain;
    overflow: hidden;
  }
}

.mask {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, .5);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;

  div {
    width: 100%;
    height: 100%;
    overflow: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  img {
    transform: scaleZ(1.2);
    cursor: pointer;
    user-select: none;
    object-fit: cover;
  }
}
</style>
